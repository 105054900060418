
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  overflow-x:hidden;
}

div.App {
  text-align: center;
  overflow-x: hidden;
}

p {
  margin-top:0;
}



video.startingVideo {
  display:none;
  
}

/*imgLogo projekt width: 474px; */

section.white {
  background-color: white;
    z-index: 100000000000000000000;
    position: fixed;
    overflow: hidden;
    height: 100%;
}



.startingTextContainer {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  z-index: 1;
  overflow: hidden;
  opacity: 1;
}

h1.startingText {
  color: #c0bccc;
  font-size: 2rem;
  text-align: center;
  font-weight:normal;
}

span.startingText-underlined {
  background-color: #c0bccc;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  text-align: center;
}

div.textBlock
 {
  animation: fadeinout 4.5s linear forwards infinite;
}

.screenFadeOut {
  animation: fadeOut ease 3s;
}

.loadingFadeOut {
  animation: fadeOut ease 2s;
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

img.title {
  position: absolute;
  left: 20px;
  bottom: 0px;
  width: 374px;
}

@media (max-width:600px){
  img.title {
    position: absolute;
    left: 0;
    right:0;
    bottom: 5%;
    width: auto;
    margin:0 auto;
  }
}

a.guidelines-underline,
a.guidelines-underline:hover,
a.guidelines-underline:visited,
a.guidelines-underline:active
 {
  background-color:white;
  color:#c0bccc;
  text-decoration:none;
}

section,
video {
  width: 100%;
  min-height: 100vh;
}

section.hero {
  background-color: #c0bccc;
}

.map {
  display: flex;
  flex-direction: column;
  position:relative;
}

#mapid {
  width: 100%;
  height: 500px;
}

@media (max-width:700px){
  #mapid {
    width: 100%;
    height: 100vh;
  }

}

.leaflet-container {
  width: 100%;
  height: 600px;
}

.mapRef-container {
  display: flex;
  flex-direction: column;
}

@media (max-width:750px){
  .mapRef-container {
    align-items: center;
  }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
color: #c0bccc;
  background-color: white;
}

h1.mapTitle {
  color: #c0bccc;
  background-color: white;
  position: absolute;
  font-weight: normal;
  right: 30px;
  z-index: 1000000000;
  font-size: 2.5rem;
  margin-bottom:0;
}

div.mapLinks {
  height: 22%;
  background-color: #c0bccc;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items:center;
  padding: 10px 20px;
  z-index: 10000000;
  position:absolute;
  bottom:0;
  left:0;
  right:0;
}

@media (max-width:700px){
  div.mapLinks {
    flex-direction:column;
    flex-wrap: nowrap;
    justify-content: center;
    height: auto;
    position:relative;
    padding-top:0;
  }

}

a.mapRef {
 color:white;
  text-decoration: none;
  margin: 5px;
  text-align: justify;
}

@media (max-width:750px){
  a.mapRef {
    font-size:0.9rem;
  }

}

.mapUnderline {
  background-color: white;
  color: #c0bccc;
}

section.breathingToGo {
  background-color: #c0bccc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.breathingGoList {
  text-align: justify;
  list-style: none;
  padding-left: 0;
}



ul.breathingGoList li {
  color: white;
  font-size: 1.4rem;
  margin: 20px;
  font-weight: normal;
}

@media (max-width:935px){
  ul.breathingGoList li {
    Font-size:1rem;
    margin-top: 0;
  }

}

@media (max-width:750px){
  ul.breathingGoList{
    padding-top:10%;
  }
}

h1.breathingGoTitle {
  color: white;
  position: absolute;
  font-weight: normal;
  right: 30px;
  top: 0;
  z-index: 1000000000;
  font-size: 2.5rem;
}

@media (max-width:850px){
  h1.breathingGoTitle {
  padding-top: 5%;
  }
}

@media (max-width:650px){
  h1.breathingGoTitle {
  padding-top: 0;
  }
}

.audioContainer {
  bottom: 60px;
  right: 30px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:750px){
 
  .audioContainer {
    bottom: 20px;
    right: 30px;
  }
}



.tooltipText {
  visibility: hidden;
  width: 120px;
  background-color:white;
  color: #c0bccc;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom:110%;
}

.tooltipVisible {
  visibility: visible;
}

.tooltipInvisible {
  visibility: hidden;
}


img.audio_icon {
  width:100px;
  cursor: pointer;
  fill:white;
}

@media (max-width:750px){
  img.audio_icon {
    width:70px;
  }

}

.playIcon {
  width: 110px;
  height:auto;
}

.stopIcon {
  width: 100px;
  height:auto;
}

video {
  object-fit: cover;
}

button {
  background-color: transparent;
  border: none;
  margin-right: 10px;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
}

@media screen and (max-width:850px){
  button {
    margin-right: 6px;
  }
}

@media screen and (max-width:675px){
  button {
    font-size:0.9rem;
    margin-right: 0px;
  }
}

.marginLeft {
  margin-left: 10px;
}

a.nav,
a.nav:hover,
a.nav:active,
a.nav:visited {
  color: white;
  text-decoration:none;
}



nav {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  color: white;
  display:flex;
}

@media screen and (max-width:675px){
  nav {
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
  }
}

@media screen and (max-width:750px){
  nav {
    right: 0px;
  }
}

.language {
  margin:0;
  padding: 0 5px;
}

.leaflet-container {
  height: 100vh;
}

@keyframes iconfadeinout {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.856);;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.856);;
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
      box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

.icon {
  background:#c0bccc;
  border: 7px solid white;
  border-radius: 50%;
  box-shadow: 0px 0px 10px white;
  color:white;
  animation: pulse 2s infinite;



}

/* animation: iconfadeinout 8s linear forwards infinite; */

.leaflet-popup-tip-container {
  margin-left: -15px;
}

.leaflet-popup-content {
  text-transform: uppercase;
}

.leaflet-container a.leaflet-popup-close-button {
  color: #c0bccc;
  
}

.icon:after {
  display: block;
  width: 15px;
  height: 15px;
  color:white;
}

section.ressources {
  background-color: #c0bccc;
  position: relative;
  display: flex;
  justify-content: center;

  color: white;
  font-size: 1.4rem;
  text-align:initial;

  line-height: 1.4;
}

div.ressources-container {
  width: 80%;
  padding-top: 10%;

}

@media (max-width:850px){
  div.ressources-container {
    font-size:1rem;
    padding-top: 18%;
  }

  section.ressources {
    padding-bottom:10%;
  }

}

@media (max-width:750px){
  div.ressources-container {
    padding-top: 20%;
    font-size: 0.9rem;
    width: 90%;
  }

}

img.ressources_cutOut {
  position: absolute;
  height: auto;
  bottom: 0;
  width: 100%;
  left: 0;
}



h1.ressource-title {
  color: white;
  text-transform: uppercase;
}

a.ressource-link,
a.ressource-link:hover,
a.ressource-link:active,
a.ressource-link:visited {
  background-color: white;
  color: #c0bccc;
  text-decoration: none;
}

section.about {
  display:flex;
  justify-content: center;
  align-items:center;
  flex-direction:column;
  position:relative;
  width:100%;
}

section.about p {

  text-align: justify;
    /* word-break: break-all; */
    line-height: 1.5;
    /* text-rendering: optimizeLegibility; */
    /* word-break: break-all; */
    hyphens: auto;
    text-justify: newspaper;
    text-align-last: left;
    font-size:0.9rem;

}

h1.about-title {
  color: #c0bccc;
  position: absolute;
  font-weight: normal;
  margin-top:15px;
  right: 30px;
  top: 0;
  z-index: 1000000000;
  font-size: 2.5rem;
}

div.about-container {
  width:95%;
  padding-top: 6%;
  display:flex;
  color:#c0bccc;
}

@media (max-width:900px){
  div.about-container {
    padding-top:10%;
  }
}

@media (max-width:950px){
  div.about-container {
    display:flex;
    flex-direction:column;
    align-items:center;
  }
}

@media (max-width:850px){
  div.about-container {
    padding-top:15%;
    padding-bottom:25%;
  }
}

.festivalLogo {
  position: absolute;
  display:flex;
  flex-direction:column;
  align-items: center;
  right: 0;
  bottom: 20px;
}


.festivalLogo img {
  width: 180px;
}





@media (max-width:1000px){
  .festivalLogo {
    right: 5px;
    bottom: 10px;
  }

  .festivalLogo img {
    width: 110px;
  }

 
}

@media (max-width:450px){
  .festivalLogo {
    position: absolute;
    bottom: 10px;
    right: 0;
    left:0;
    margin:0 auto;
  }


.festivalLogo img {
  width: 70px;
}

}


div.about-container div {
  margin:0 30px;
  max-width:33%;
  width:33%;
}



@media (max-width:900px){
  
div.about-container div {
  max-width:95%;
  width:95%;
  margin:0;
  padding:0 15px;
}
}




#about_first  {
  font-size:1.1rem;
  font-weight:bold;
}

a.textUnderline,
a.textUnderline:hover,
a.textUnderline:active,
a.textUnderline:visited
 {
  color:white;
  background-color:#c0bccc;
  text-decoration:none;
}

section.imprint {
  background-color: #c0bccc;
  color:white;
  display:flex;
  justify-content: center;
  align-items:center;
}

.closeImprint {
  background-color: white;
  color:#c0bccc;
  font-size:1.2rem;
  cursor:pointer;
}

div.impressum_text {
  width: 61%;
  font-size:0.9rem;
}

span.imprint {
  cursor:pointer;
}


audio {
  display:none;
}


footer {
  min-height: 100px;
  width: 100%;
  background-color: #c0bccc;
  color:white;
  line-height: 1.5;
  font-size: 1.1rem;
  padding: 10px 0;
}

@media (max-width:850px){
  h1, 
  h1.startingText,
  h1.mapTitle, 
  h1.breathingGoTitle,
  h1.about-title,
  h1.ressource-title {
    font-size:2rem;
    right:15px;
  }
}

@media (max-width:500px){
  h1, 
  h1.startingText,
  h1.mapTitle, 
  h1.breathingGoTitle,
  h1.about-title,
  h1.ressource-title {
    font-size: 1.5rem;
    right:10px;
  }
}

@media (max-width:350px){
  h1, 
  h1.startingText,
  h1.mapTitle, 
  h1.breathingGoTitle,
  h1.about-title,
  h1.ressource-title {
    font-size: 1.5rem;
    right:10px;
  }
}
